import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  font-size: 24px;

  @media only screen and (min-width: 768px) {
    font-size: 48px;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    width: 100%;
    height: 100vh;
    background: #001122;
    display: grid;
    place-items: center;
    font-weight: 800;
  }
  .loader {
    perspective: 1000px;
    -webkit-perspective: 1000px;
  }
  .loader > span {
    display: inline-block;
    color: #fff;
    transform-style: preserve-3d;
    transform-origin: 0 100%;
    animation: anim 3s infinite linear;
  }
  .loader > span:nth-child(even) {
    color: var(--primary);
  }
  .loader > span:nth-child(2) {
    animation-delay: 0.2s;
  }
  .loader > span:nth-child(3) {
    animation-delay: 0.4s;
  }
  .loader > span:nth-child(4) {
    animation-delay: 0.6s;
  }
  .loader > span:nth-child(5) {
    animation-delay: 0.8s;
  }
  .loader > span:nth-child(6) {
    animation-delay: 1s;
  }
  .loader > span:nth-child(7) {
    animation-delay: 1.2s;
  }
  .loader > span:nth-child(8) {
    animation-delay: 1.4s;
  }
  .loader > span:nth-child(9) {
    animation-delay: 1.6s;
  }
  .loader > span:nth-child(10) {
    animation-delay: 1.8s;
  }
  .loader > span:nth-child(11) {
    animation-delay: 2s;
  }
  .loader > span:nth-child(12) {
    animation-delay: 2.2s;
  }
  .loader > span:nth-child(13) {
    animation-delay: 2.4s;
  }
  .loader > span:nth-child(14) {
    animation-delay: 2.6s;
  }
  .loader > span:nth-child(15) {
    animation-delay: 2.8s;
  }
  @keyframes anim {
    35% {
      transform: rotateX(360deg);
    }
    100% {
      transform: rotateX(360deg);
    }
  }
`;

const DancingLetters = () => {
  return (
    <Content>
      <div className="loader">
        <span>1</span>
        <span>_</span>
        <span>P</span>
        <span>R</span>
        <span>I</span>
        <span>M</span>
        <span>E</span>
        <span>_</span>
        <span>I</span>
        <span>S</span>
        <span>_</span>
        <span>N</span>
        <span>O</span>
        <span>T</span>
        <span>_</span>
        <span>$</span>
        <span>1</span>
        <span>1</span>
        <span>1</span>
      </div>
    </Content>
  );
};

export default DancingLetters;
