import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  font-size: 8px;
`;

const NoSmall = () => {
  return <Content>No, but very small.</Content>;
};

export default NoSmall;
