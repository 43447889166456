import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  .container {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
  }

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .title h2 {
    font-weight: 900;
    color: white;

    font-size: 16px;

    @media only screen and (min-width: 768px) {
      font-size: 60px;
    }
  }

  .ghost {
    width: 8vmin;
    height: 12vmin;
    background-color: var(--white);
    // background-image: radial-gradient(ellipse at 35% 40%, #000 8%, transparent 0%),
    // 	radial-gradient(ellipse at 65% 40%, #000 8%, transparent 0%),
    // 	radial-gradient(ellipse at 50% 60%, #000 8%, transparent 0%);
    // border-radius: 100% / 70% 70% 0% 0%;
    // transform: translateX(100em) rotateZ(-90deg);
    position: relative;
    opacity: 0.9;
    mix-blend-mode: exclusion;
    animation: ghostMove 4s linear infinite;

    @keyframes ghostMove {
      0% {
        transform: translateX(25em);
      }
      100% {
        transform: translateX(-25em);
      }
    }

    @media only screen and (min-width: 768px) {
      @keyframes ghostMove {
        0% {
          transform: translateX(70em);
        }
        100% {
          transform: translateX(-70em);
        }
      }
    }
  }
  .ghost div {
    position: absolute;
    width: 20%;
    background-color: var(--primary);
  }
`;

const MovingLogo = () => {
  return (
    <Content>
      <div className="title">
        <h2>IT`S PROGRAMMED</h2>
      </div>
      <div className="ghost" />
    </Content>
  );
};

export default MovingLogo;
