import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  body {
    background: #333;
    padding-top: 5em;
    display: flex;
    justify-content: center;
  }

  .typewriter h3 {
    color: #fff;
    font-size: 10px;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0.15em solid orange; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: 0.15em; /* Adjust as needed */
    animation: typing 3.5s steps(30, end), blink-caret 0.75s step-end infinite;

    @media only screen and (min-width: 768px) {
      font-size: 20px;
    }
  }

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: var(--primary);
    }
  }
`;

const NoButRome = () => {
  return (
    <Content>
      <div className="typewriter">
        <h3>NO, BUT ROME WASN`T BUILD IN ONE DAY</h3>
      </div>
    </Content>
  );
};

export default NoButRome;
