import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  .center {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  .table {
    width: 90%;
    height: 75px;
    background-color: #d4e5ff;

    @media only screen and (min-width: 768px) {
      height: 150px;
    }
  }

  .monitor-wrapper {
    background: #050321;
    width: 90%;
    height: 65px;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.3);

    @media only screen and (min-width: 768px) {
      height: 130px;
    }
  }

  .monitor {
    width: 90%;
    height: 50px;
    background-color: #344151;
    overflow: hidden;
    white-space: nowrap;
    box-shadow: inset 0px 5px 10px 2px rgba(0, 0, 0, 0.3);

    @media only screen and (min-width: 768px) {
      height: 100px;
    }
  }
`;

const Paragraph = styled.p`
  font-size: 40px;
  position: relative;
  display: inline-block;
  animation: move 6s infinite linear;
  color: var(--primary);

  @keyframes move {
    from {
      left: 800px;
    }
    to {
      left: -1200px;
    }
  }

  @media only screen and (min-width: 768px) {
    font-size: 80px;
    animation: move 12s infinite linear;

    @keyframes move {
      from {
        left: 800px;
      }
      to {
        left: -2400px;
      }
    }
  }
`;

const SlidingText = () => {
  return (
    <Content>
      <div className="table center">
        <div className="monitor-wrapper center">
          <div className="monitor center">
            <Paragraph>No, but the price of $111 per PRIME is programmed. You know this.</Paragraph>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default SlidingText;
