import React, {useState} from "react";
import styled from "styled-components";

const Content = styled.div``  

const Subheading = styled.h2`
    font-size: 32px;
    color: var(--white);
    text-transform: uppercase;
    margin: 0 0 20px; 
`

const Button = styled.button`
    border: 2px solid var(--primary);
    color: var(--primary);
    margin: 0 auto;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    padding: 8px 12px;
    
    &:hover {
        background: var(--primary);
        color: #000;
    }
    &:active {
        transform: scale(1.1);
    }
`

const ButtonDisabled = styled.div`
	position: relative;
    margin: 0 auto;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    padding: 8px 12px;
    border: 2px solid #000;
    color: var(--primary);
`

const Increaser = () => {
    const [price, setPrice] = useState(0)
	const handleClick = () => setPrice(price + 1)

    return (
        <Content>
            <Subheading>1 PRIME = ${price}</Subheading>
            {price < 111 ?
                ( 
                    <Button onClick={handleClick}>higher</Button>)
                : (
                    <ButtonDisabled>IT'S PROGRAMMED</ButtonDisabled>
                )
            }
        </Content>
    )
}

export default Increaser;