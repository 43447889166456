import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
`;

const Quote = styled.p`
  font-size: 16px;
  font-style: italic;

  @media only screen and (min-width: 768px) {
    font-size: 32px;
  }
`;

const Author = styled.p`
  font-size: 12px;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }
`;

const Daemonicek = () => {
  return (
    <Content>
      <Quote>&quot;I had a dreeeam… 1️⃣1️⃣1️⃣🆙 💁‍♀️&quot;</Quote>
      <Author>- Daemonicek</Author>
    </Content>
  );
};

export default Daemonicek;
