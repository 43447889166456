import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

const Content = styled.div``;

const ImageHolder = styled.div`
  &:hover {
    animation: horizontal-shaking 0.25s infinite;
    cursor: pointer;
    @keyframes horizontal-shaking {
      0% {
        transform: translateX(0);
      }
      25% {
        transform: translateX(5px);
      }
      50% {
        transform: translateY(-5px);
        box-shadow: 0px 0px 80px 20px rgba(222, 241, 65, 0.5);
      }
      75% {
        transform: translateX(5px);
      }
      100% {
        transform: translateX(0);
        box-shadow: 0px 0px 80px 20px rgba(222, 241, 65, 1);
      }
    }
    box-shadow: -0p 0px 120px 40px rgba(222, 241, 65, 1);
  }
`;

const ImageNo = () => {
  return (
    <Content>
      <ImageHolder>
        <StaticImage
          src="../Images/image-no.png"
          alt="No"
          placeholder="blurred"
          width={250}
          layout="fixed"
        />
      </ImageHolder>
    </Content>
  );
};

export default ImageNo;
