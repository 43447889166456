import React, { FunctionComponent, useState } from 'react';
import { PageProps } from 'gatsby';
import styled from 'styled-components';

import ReactGA from 'react-ga4';

import FloatingCoin from '../components/Answers/floating-coin';
import Marcolian from '../components/Answers/marcolian';
import TurningBox from '../components/Answers/turning-box';
import Shroud from '../components/Answers/shroud';
import Kathari from '../components/Answers/kathari';
import Earthen from '../components/Answers/earthen';
import Augencore from '../components/Answers/augencore';
import { SliderNoNo } from '../components/Answers/slider-no-no';
import ImageNo from '../components/Answers/image-no';
import Smile from '../components/Answers/smile';
import Daemonicek from '../components/Answers/daemonicek';
import NoSmall from '../components/Answers/no-small';
import NoGrid from '../components/Answers/no-grid';
import FlippingBox from '../components/Answers/flipping-box';
import MovingWords from '../components/Answers/moving-words';
import ShellBeach from '../components/Answers/shell-beach';
import NaynVoice from '../components/Answers/nayn-voice';
import Increaser from '../components/Answers/increaser';
import { ThreeBox } from '../components/Answers/three-box/three-box';
import DancingLetters from '../components/Answers/dancing-letters';
import Shaking from '../components/Answers/shaking';
import DroppingText from '../components/Answers/dropping-text';
import NoNeon from '../components/Answers/no-neon';
import MovingBg from '../components/Answers/moving-bg';
import NoButRome from '../components/Answers/no-but-rome';
import Smoke from '../components/Answers/smoke';
import RotateText from '../components/Answers/rotate-text';
import BounceText from '../components/Answers/bounce-text';
import NaynQuote from '../components/Answers/nayn-quote';
import SlidingColor from '../components/Answers/sliding-color';
import WaterLevel from '../components/Answers/water-level';
import TextStripes from '../components/Answers/text-stripes';
import ThreeDText from '../components/Answers/threeD-text';
import ItsProgrammed from '../components/Answers/no-but-primed';
import MovingLogo from '../components/Answers/moving-logo';
import ChangingWords from '../components/Answers/changing-words';
import RotatingWordsCircle from '../components/Answers/rotating-words-circle';
import SlidingText from '../components/Answers/sliding-text';
import LennyStyles from '../components/Answers/lenny-styles';
import Shill3r from '../components/Answers/shill3r';
import ScottFromHR from '../components/Answers/scot-from-hr';

ReactGA.set({ anonymizeIp: true });
ReactGA.initialize('G-W5JH4QMQ7E');

const Container = styled.section`
  display: grid;
  grid-template-rows: 1fr 15%;
  align-items: space-between;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #000;
  color: #fff;
  text-align: center;
  // flex-direction: column;
  gap: 50px;

  @media only screen and (min-width: 768px) {
    grid-template-rows: 1fr 10%;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100vw - 64px);
  border: 1px solid rgb(222, 241, 65, 0.3);
`;

const ButtonsWrapper = styled.div``;

const ReloadButton = styled.div`
  position: relative;
  margin: 0 auto;
  width: 150px;
  animation: grow 1.5s infinite ease-out;
  height: 36px;

  @keyframes grow {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }

  .button {
    text-align: center;
    color: var(--primary);
    font-size: 14px;
    font-weight: 400;
    padding: 10px 6px 10px 10px;
    letter-spacing: 3px;
  }

  &:hover {
    cursor: pointer;
  }

  .border {
    background: var(--primary);
  }

  .top {
    position: absolute;
    top: 0px;
    left: 50%;
    width: 0;
    height: 1px;
    margin: auto;
    opacity: 0;
    transition: all 0.2s linear 0.3s;
  }

  &:hover .top {
    left: 0;
    width: 100%;
    opacity: 1;
    transition: all 0.2s linear;
  }

  .right {
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 0;
    margin: auto;
    transition: all 0.1s linear 0.2s;
  }

  .left {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 0;
    margin: auto;
    transition: all 0.1s linear 0.2s;
  }

  &:hover .right,
  &:hover .left {
    transition: all 0.1s linear 0.2s;
    height: 100%;
  }

  .bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    margin: auto;
    transition: all 0.2s linear;
  }

  .bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 1px;
    margin: auto;
    transition: all 0.2s linear;
  }

  &:hover .bottom-left,
  &:hover .bottom-right {
    transition: all 0.2s linear 0.3s;
    width: 100%;
  }

  &:active .top,
  &:active .bottom-right,
  &:active .bottom-left,
  &:active .right,
  &:active .left {
    width: 0;
    transition: none;
  }
`;

const Counter = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
  top: 16px;
  right: 16px;
  color: var(--primary);
  font-size: 12px;
`;

const PrevButton = styled.p`
  cursor: pointer;
  font-size: 16px;
  &:hover {
    color: white;
  }
`;

const NextButton = styled.p`
  cursor: pointer;
  font-size: 16px;
  &:hover {
    color: white;
  }
`;

const ButtonWrapper = styled.div`
	font-size: 10px;
	opacity: .5;
	margin: 10px; 0 0 0;

	&:hover {
		opacity: 1;
		cursor: pointer;
	}
`;

const FormOverlay = styled.div<{ isOpen: boolean }>`
  height: 100%;
  width: 100%;
  background: rgb(0, 0, 0, 0.9);
  background: rgb(255, 255, 255, 0.05);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${({ isOpen }) => `
		${
      isOpen
        ? `
			display: block;
		`
        : `
			display: none;
		`
    }
	`}
`;

const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0;
  flex-wrap: wrap;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  margin: 0 auto;
  background: black;
  border: 1px solid var(--primary);
  height: 75%;
  width: 84%;
  color: var(--primary);

  @media only screen and (min-width: 768px) {
    width: 70%;
  }
`;

const FormHeading = styled.h3`
  font-size: 18px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    font-size: 40px;
  }
`;

const FormDescription = styled.p`
  font-size: 12px;
  line-height: 150%;
  font-style: italic;
  text-align: center;
  margin: 0 auto;
  width: 80%;
`;

const Form = styled.form<{ isSend: boolean }>`
  width: 100%;
  height: 70%;
  overflow: scroll;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${({ isSend }) => `
		${
      isSend
        ? `
			pointer-events:none;
			opacity: .25;
		`
        : ``
    }
	`}
`;

const SubmitButton = styled.button<{ isSend: boolean }>`
  color: var(--primary);
  font-size: 24px;
  margin: 0 auto;

  &:active {
    transform: scale(1.1);
  }
  &:hover {
    &:after {
      animation: send 0.25s ease-out;

      @keyframes send {
        0% {
          padding: 0;
          opacity: 0;
        }
        100% {
          padding: 0 0 0 10px;
          opacity: 1;
        }
      }
      content: ' >';
    }
  }
  ${({ isSend }) => `
		${
      isSend
        ? `
			display: none;
		`
        : `
			display: block;
		`
    }
	`}
`;

const ThankYouMessage = styled.span<{ isSend: boolean }>`
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;

  ${({ isSend }) => `
		${
      isSend
        ? `
			display: block;
			color: var(--earthen);
			font-size: 24px;
			filter: brightness(1.5);
		`
        : `
			display: none;
		`
    }
	`}
`;

const CloseForm = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  &:after {
    font-size: 24px;
    content: 'X';
  }

  @media only screen and (min-width: 768px) {
    top: 16px;
    right: 24px;

    &:after {
      font-size: 40px;
    }
  }
`;

const Paragraph = styled.p`
  width: 100%;
`;

const Label = styled.label`
  width: 100%;
  font-size: 14px;
`;

const Input = styled.input`
  display: block;
  margin: 10px auto 0;
  font-size: 14px;
  color: white;
  line-height: 150%;
  padding: 8px;
  width: 77%;
  background: rgb(255, 255, 255, 0.2);

  @media only screen and (min-width: 768px) {
    textarea {
      height: 150px;
    }
    label {
      font-size: 18px;
    }
  }
`;

const Textarea = styled.textarea`
  display: block;
  margin: 10px auto 0;
  height: 100px;
  font-size: 14px;
  color: white;
  line-height: 150%;
  padding: 8px;
  width: 77%;
  background: rgb(255, 255, 255, 0.2);
`;

const AnswersArray = [
  'FloatingCoin',
  'TurningBox',
  'Augencore',
  'Earthen',
  'Kathari',
  'Marcolian',
  'Shroud',
  'SliderNoNo',
  'ImageNo',
  'Smile',
  'NoSmall',
  'Daemonicek',
  'NoGrid',
  'FlippingBox',
  'MovinWords',
  'ShellBeach',
  'NaynVoice',
  'Increaser',
  'ThreeBox',
  'DancingLetters',
  'DroppingText',
  'NoNeon',
  'MovingBg',
  'NoButRome',
  'Smoke',
  'RotateText',
  'BounceText',
  'NaynQuote',
  'SlidingColor',
  'WaterLevel',
  'TextStripes',
  'ThreeDText',
  'ItsProgrammed',
  'MovingLogo',
  'ChangingWords',
  'RotatingWordsCircle',
  'SlidingText',
  'LennyStyles',
  'Shill3r',
  'ScottFromHR',
];

function encode(data: any) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const getRandomNum = (min: number, AnswersArrayLength: number) =>
  Math.floor(Math.random() * (AnswersArrayLength - min)) + min;

const Index: FunctionComponent<PageProps> = () => {
  const AnswersArrayLength = AnswersArray.length;
  const [randomNum, setRandomNum] = useState(getRandomNum(0, AnswersArrayLength));
  const handleClick = () => setRandomNum(getRandomNum(0, AnswersArrayLength));
  // const [randomNum, setRandomNum] = useState(40);

  const handleNext = () => (randomNum < AnswersArrayLength ? setRandomNum(randomNum + 1) : '');
  const handlePrev = () => (randomNum !== 0 ? setRandomNum(randomNum - 1) : '');

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const openForm = () => (!isOpen ? setIsOpen(true) : setIsOpen(false));
  const closeForm = () => (isOpen ? setIsOpen(false) : setIsOpen(true));

  const [state, setState] = React.useState({});

  const [isSend, setIsSend] = useState<boolean>(false);
  const formSend = () => setIsSend(true);

  const handleChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => formSend())
      .catch((error) => alert(error));
  };

  return (
    <>
      <div
        key="loader"
        id="___loader"
        style={{
          alignItems: 'center',
          backgroundColor: '#fff',
          display: 'flex',
          color: '#000',
          fontSize: '24px',
          justifyContent: 'center',
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 99,
        }}
      >
        &#47; &#47;
      </div>
      <Container>
        <ContentWrapper id={AnswersArray[randomNum - 1]}>
          {randomNum === 0 && <Increaser />}
          {randomNum === 1 && <TurningBox />}
          {randomNum === 2 && <Augencore />}
          {randomNum === 3 && <Earthen />}
          {randomNum === 4 && <Kathari />}
          {randomNum === 5 && <Marcolian />}
          {randomNum === 6 && <Shroud />}
          {randomNum === 7 && <SliderNoNo />}
          {randomNum === 8 && <ImageNo />}
          {randomNum === 9 && <Smile />}
          {randomNum === 10 && <NoSmall />}
          {randomNum === 11 && <Daemonicek />}
          {randomNum === 12 && <NoGrid />}
          {randomNum === 13 && <FlippingBox />}
          {randomNum === 14 && <MovingWords />}
          {randomNum === 15 && <ShellBeach />}
          {randomNum === 16 && <NaynVoice />}
          {randomNum === 17 && <FloatingCoin />}
          {randomNum === 18 && <ThreeBox />}
          {randomNum === 19 && <DancingLetters />}
          {randomNum === 20 && <Shaking />}
          {randomNum === 21 && <DroppingText />}
          {randomNum === 22 && <NoNeon />}
          {randomNum === 23 && <MovingBg />}
          {randomNum === 24 && <NoButRome />}
          {randomNum === 25 && <Smoke />}
          {randomNum === 26 && <RotateText />}
          {randomNum === 27 && <BounceText />}
          {randomNum === 28 && <NaynQuote />}
          {randomNum === 29 && <SlidingColor />}
          {randomNum === 30 && <WaterLevel />}
          {randomNum === 31 && <TextStripes />}
          {randomNum === 32 && <ThreeDText />}
          {randomNum === 33 && <ItsProgrammed />}
          {randomNum === 34 && <MovingLogo />}
          {randomNum === 35 && <ChangingWords />}
          {randomNum === 36 && <RotatingWordsCircle />}
          {randomNum === 37 && <SlidingText />}
          {randomNum === 38 && <LennyStyles />}
          {randomNum === 39 && <Shill3r />}
          {randomNum === 40 && <ScottFromHR />}
          <Counter>
            <PrevButton onClick={handlePrev}>&#171;</PrevButton>
            {randomNum + 1} / {AnswersArrayLength + 1}
            <NextButton onClick={handleNext}>&#187;</NextButton>
          </Counter>
        </ContentWrapper>
        <ButtonsWrapper>
          <ReloadButton onClick={handleClick}>
            <div className="button">NEW ANSWER?</div>
            <div className="border top" />
            <div className="border left" />
            <div className="border right" />
            <div className="border bottom-left" />
            <div className="border bottom-right" />
          </ReloadButton>
          <ButtonWrapper onClick={openForm}>Add your answer?</ButtonWrapper>
          <FormOverlay isOpen={isOpen}>
            <FormWrapper>
              <FormHeading>Submit your answer</FormHeading>
              <FormDescription>
                Would you like to add your quote? Or do you have a nice snippet to add? Upload it to
                codepen and share your link.
              </FormDescription>
              <Form
                name="contact"
                method="post"
                // action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
                isSend={isSend}
              >
                <Input type="hidden" name="form-name" value="contact" />
                <Paragraph hidden>
                  <Label>
                    Don`t fill this out: <Input name="bot-field" onChange={handleChange} />
                  </Label>
                </Paragraph>
                <Paragraph>
                  <Label>
                    Your name:
                    <Input type="text" name="name" onChange={handleChange} />
                  </Label>
                </Paragraph>
                <Paragraph>
                  <Label>
                    Quote/Message:
                    <Textarea name="message" onChange={handleChange} />
                  </Label>
                </Paragraph>
                <Paragraph>
                  <Label>
                    Link to your codepen.io snippet:
                    <Input type="text" name="snippet" onChange={handleChange} />
                  </Label>
                </Paragraph>
                <Paragraph>
                  <SubmitButton isSend={isSend} type="submit">
                    Send
                  </SubmitButton>
                </Paragraph>
              </Form>
              <ThankYouMessage isSend={isSend}>Thank you for your message!</ThankYouMessage>
              <CloseForm onClick={closeForm} />
            </FormWrapper>
          </FormOverlay>
        </ButtonsWrapper>
      </Container>
    </>
  );
};

export default Index;
