import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  .drop-main {
    display: flex;
    font-size: 40px;
    margin: 1.5rem;
    justify-content: center;
  }
  @media only screen and (min-width: 768px) {
    font-size: 80px;
  }
  .d {
    opacity: 0;
    animation: drop 0.4s linear forwards;
  }
  .r {
    opacity: 0;
    animation: drop 0.4s linear forwards 0.2s;
  }
  .o {
    opacity: 0;
    animation: drop 0.4s linear forwards 0.4s;
  }
  .p {
    opacity: 0;
    animation: drop 0.4s linear forwards 0.6s;
  }
  .s {
    opacity: 0;
    animation: drop 0.4s linear forwards 0.8s;
  }
  @keyframes drop {
    0% {
      transform: translateY(-200px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    65% {
      transform: translateY(-17px);
      opacity: 1;
    }
    75% {
      transform: translateY(-22px);
      opacity: 1;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;

const DroppingText = () => {
  return (
    <Content>
      <div className="wrapper one">
        <div className="drop-main">
          <div className="d">N</div>
          <div className="r">O</div>
          <div className="o">P</div>
          <div className="p">E</div>
          <div className="s">!</div>
        </div>
      </div>
    </Content>
  );
};

export default DroppingText;
