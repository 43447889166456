import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  padding: 0 16px;
`;

const Quote = styled.p`
  font-size: 16px;
  font-style: italic;

  @media only screen and (min-width: 768px) {
    font-size: 32px;
  }
`;

const Author = styled.p`
  margin: 30px 0 0;
  font-size: 12px;

  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }
`;

const NaynQuote = () => {
  return (
    <Content>
      <Quote>
        At this point, it is both nothing and everything,
        <br /> $1 and $111, it is what you want it to be!
      </Quote>
      <Author>- Nayn</Author>
    </Content>
  );
};

export default NaynQuote;
