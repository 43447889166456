import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  .bounce {
    font-size: 5rem;
    letter-spacing: 5px;
    width: 100%;
    margin: 3rem auto;
    display: inline-flex;
    justify-content: center;
    -webkit-box-reflect: below -20px linear-gradient(transparent, #211e1e2e);
  }

  .bounce span {
    display: inline-flex;
    color: white;
    animation: bounce 1s infinite;
  }

  @keyframes bounce {
    0%,
    50%,
    100% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-20px);
    }
  }
  .1 {
    animation-delay: 0.1s;
  }
  .2 {
    animation-delay: 0.2s;
  }
  .3 {
    animation-delay: 0.3s;
  }
  .4 {
    animation-delay: 0.4s;
  }
  .5 {
    animation-delay: 0.5s;
  }
  .6 {
    animation-delay: 0.6s;
  }
  .7 {
    animation-delay: 0.7s;
  }
`;

const BounceText = () => {
  return (
    <Content>
      <div className="ten">
        <div>
          <h3 className="bounce">
            <span className="1">N</span>
            <span className="2">0</span>
            <span className="3">T</span>
            <span className="4">_</span>
            <span className="5">Y</span>
            <span className="6">E</span>
            <span className="7">T</span>
          </h3>
        </div>
      </div>
    </Content>
  );
};

export default BounceText;
