import React from "react";
import styled from "styled-components";

const Content = styled.div``  

const Subheading = styled.h2`
    color: var(--kathari);
    font-size: 16px;
    text-transform: uppercase;

    @media only screen and (min-width: 768px) {
        font-size: 32px;
    }
`

const Kathari = () => {
    return (
        <Content>
            <Subheading>No, but in Kathari blue</Subheading>
        </Content>
    )
}

export default Kathari;