import React from 'react';
import styled from 'styled-components';

const Content = styled.div``;

const Subheading = styled.h2`
  font-size: 18px;
  color: var(--white);
  text-transform: uppercase;

  @media only screen and (min-width: 768px) {
    font-size: 32px;
  }
`;

const NaynVoice = () => {
  return (
    <Content>
      <Subheading>No, but in Nayn`s voice.</Subheading>
    </Content>
  );
};

export default NaynVoice;
