import React, { ReactNode } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import styled from 'styled-components';

const Styling = styled.div`
  .item {
    position: relative;
    width: 100px;
    height: 40px;
    pointer-events: auto;
    transform-origin: 50% 50% 0px;
    padding: 0 32px;
    box-sizing: border-box;
    display: grid;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.2);
    -webkit-user-select: none;
    user-select: none;

    @media only screen and (min-width: 768px) {
      width: 180px;
      height: 70px;
      padding: 0 32px;
    }
  }

  .fg {
    cursor: -webkit-grab;
    background-color: #272727;
    color: var(--primary);
    position: absolute;
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    font-weight: 600;
    transition: box-shadow 0.75s;

    @media only screen and (min-width: 768px) {
      font-size: 24px;
    }
  }

  .fg:active {
    cursor: -webkit-grabbing;
    box-shadow: 0px 15px 30px -5px rgba(0, 0, 0, 0.4);
  }

  .fg > * {
    pointer-events: none;
  }

  .av {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    font-size: 40px;
  }

  .container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
`;

const left = {
  bg: `linear-gradient(90deg, #000 0%, #DEF141 100%)`,
  justifySelf: 'end',
};
const right = {
  bg: `linear-gradient(90deg, #DEF141 0%, #000 100%)`,
  justifySelf: 'start',
};

const Slider = ({ children }: { children: ReactNode }) => {
  const [{ x, bg, scale, justifySelf }, api] = useSpring(() => ({
    x: 0,
    scale: 1,
    ...left,
  }));
  const bind = useDrag(({ active, movement: [x] }) =>
    api.start({
      x: active ? x : 0,
      scale: active ? 1.1 : 1,
      ...(x < 0 ? left : right),
      immediate: (name) => active && name === 'x',
    })
  );

  const avSize = x.to({
    map: Math.abs,
    range: [50, 300],
    output: [0.5, 1],
    extrapolate: 'clamp',
  });

  return (
    <Styling>
      <animated.div {...bind()} className="item" style={{ background: bg }}>
        <animated.div className="av" style={{ scale: avSize, justifySelf }}>
          NO
        </animated.div>
        <animated.div className="fg" style={{ x, scale }}>
          {children}
        </animated.div>
      </animated.div>
    </Styling>
  );
};

export function SliderNoNo() {
  return (
    <div className="container">
      <Slider>{'< SLIDE >'}</Slider>
    </div>
  );
}
