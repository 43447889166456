import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    display: flex;
    background: #000;
    align-items: center;
    justify-content: center;
  }

  .content {
    position: relative;
  }

  .content h2 {
    color: #fff;
    font-size: 40px;
    position: absolute;
    transform: translate(-50%, -50%);

    @media only screen and (min-width: 768px) {
      font-size: 80px;
    }
  }

  .content h2:nth-child(1) {
    color: transparent;
    -webkit-text-stroke: 1px var(--kathari);

    @media only screen and (min-width: 768px) {
      -webkit-text-stroke: 2px var(--kathari);
    }
  }

  .content h2:nth-child(2) {
    color: var(--kathari);
    animation: animate 4s ease-in-out infinite;
  }

  @keyframes animate {
    0%,
    100% {
      clip-path: polygon(
        0% 45%,
        16% 44%,
        33% 50%,
        54% 60%,
        70% 61%,
        84% 59%,
        100% 52%,
        100% 100%,
        0% 100%
      );
    }

    50% {
      clip-path: polygon(
        0% 60%,
        15% 65%,
        34% 66%,
        51% 62%,
        67% 50%,
        84% 45%,
        100% 46%,
        100% 100%,
        0% 100%
      );
    }
  }
`;

const WaterLevel = () => {
  return (
    <Content>
      <div className="content">
        <h2>SOON?</h2>
        <h2>SOON?</h2>
      </div>
    </Content>
  );
};

export default WaterLevel;
