import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 20px;
    display: block;
    // width: 960px;
    height: 300px;
    margin: 0 auto;

    @media only screen and (min-width: 768px) {
      font-size: 80px;
    }
  }

  .text-copy {
    fill: none;
    stroke: white;
    stroke-dasharray: 6% 29%;
    stroke-width: 2px;
    stroke-dashoffset: 0%;
    animation: stroke-offset 5.5s infinite linear;
  }

  .text-copy:nth-child(1) {
    stroke: var(--primary);
    animation-delay: -1;
  }

  .text-copy:nth-child(2) {
    stroke: var(--primary);
    animation-delay: -2s;
  }

  .text-copy:nth-child(3) {
    stroke: var(--primary);
    animation-delay: -3s;
  }

  .text-copy:nth-child(4) {
    stroke: var(--primary);
    animation-delay: -4s;
  }

  .text-copy:nth-child(5) {
    stroke: white;
    animation-delay: -5s;
  }

  @keyframes stroke-offset {
    100% {
      stroke-dashoffset: -35%;
    }
  }
`;

const MobileContent = styled.div`
  display: block;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const DesktopContent = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    display: block;
  }
`;

const TextStripes = () => {
  return (
    <Content>
      <MobileContent>Nayn, Nayn, Nayn :&#41;</MobileContent>
      <DesktopContent>
        <svg viewBox="0 0 960 100">
          <symbol id="s-text">
            {/* <text text-anchor="middle" x="50%" y="80%">IT`S PROGRAMMED</text> */}
            <text textAnchor="middle" x="52%" y="80%">
              IT`S PROGRAMMED
            </text>
          </symbol>

          <g className="g-ants">
            <use xlinkHref="#s-text" className="text-copy" />
            <use xlinkHref="#s-text" className="text-copy" />
            <use xlinkHref="#s-text" className="text-copy" />
            <use xlinkHref="#s-text" className="text-copy" />
            <use xlinkHref="#s-text" className="text-copy" />
          </g>
        </svg>
      </DesktopContent>
    </Content>
  );
};

export default TextStripes;
