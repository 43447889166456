import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 6% 0 0 0;
  justify-content: center;
  background: url(https://parallel.life/images/bgEarth.webp) no-repeat;
  animation: animatedOne 30s linear infinite;
  background-position: bottom center;
  background-size: cover;

  @keyframes animatedOne {
    0%,
    100% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 0;
    }
  }

  @media only screen and (min-width: 768px) {
    background-size: contain;
    @keyframes animatedOne {
      0%,
      100% {
        background-position: 0 0;
      }
      50% {
        background-position: 100% 0;
      }
    }
  }
`;

const Paragraph = styled.p`
  font-size: 20px;
`;

const MovingBg = () => {
  return (
    <Content>
      <Paragraph>NOOOOOOOOO</Paragraph>
    </Content>
  );
};

export default MovingBg;
