import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  padding: 0 16px;
`;

const Quote = styled.p`
  font-size: 16px;
  font-style: italic;

  @media only screen and (min-width: 768px) {
    font-size: 32px;
  }
`;

const Author = styled.p`
  margin: 30px 0 0;
  font-size: 12px;

  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }
`;

const ShellBeach = () => {
  return (
    <Content>
      <Quote>
        &quot;this 111$ prime is a joke
        <br />
        lets at least start with 10$
        <br />
        so we are grounded a bit
        <br />
        even thats high&quot;
        <br />
      </Quote>
      <Author>- 🐚 🏖️</Author>
    </Content>
  );
};

export default ShellBeach;
