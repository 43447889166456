import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  padding: 0 16px;
`;

const Quote = styled.p`
  font-size: 16px;
  font-style: italic;

  @media only screen and (min-width: 768px) {
    font-size: 32px;
  }
`;

const Author = styled.p`
  margin: 30px 0 0;
  font-size: 16px;

  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }
`;

const ScottFromHR = () => {
  return (
    <Content>
      <Quote>
        &quot;There`s a lot of delusional people here that will tell you otherwise but the math
        doesn`t align. When you point out their fallacious thinking, their closing statement is
        always, “we like the cards.&quot;
      </Quote>
      <Author>- Scott from Human Resources</Author>
    </Content>
  );
};

export default ScottFromHR;
