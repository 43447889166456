import React from 'react'
import styled from "styled-components";
import { useTrail, useChain, useSprings, animated, useSpringRef } from '@react-spring/web'

const Content = styled.div`
	color: var(--primary);
	padding: 16px;
`
const COORDS = [
	[30, 20],
	[30, 30],
	[30, 40],
	[30, 60],
	[30, 50],
	[30, 60],
	[30, 70],
	[40, 20],
	[50, 20],
	[60, 20],
	[60, 30],
	[60, 40],
	[60, 50],
	[60, 60],
	[60, 70],
	[80, 20],
	[90, 20],
	[100, 20],
	[110, 20],
	[110, 30],
	[110, 40],
	[110, 50],
	[110, 60],
	[110, 70],
	[100, 70],
	[90, 70],
	[80, 70],
	[80, 70],
	[80, 60],
	[80, 50],
	[80, 40],
	[80, 30],
  ]
  
  const STROKE_WIDTH = 0.5
  
  const OFFSET = STROKE_WIDTH / 2
  
  const MAX_WIDTH = 150 + OFFSET * 2
  const MAX_HEIGHT = 100 + OFFSET * 2


const NoGrid = () => {
	const gridApi = useSpringRef()
  
	const gridSprings = useTrail(16, {
		ref: gridApi,
		from: {
			x2: 0,
			y2: 0,
		},
		to: {
			x2: MAX_WIDTH,
			y2: MAX_HEIGHT,
		},
	})
  
	const boxApi = useSpringRef()
  
	const [boxSprings] = useSprings(32, i => ({
	  ref: boxApi,
	  from: {
		scale: 0,
	  },
	  to: {
		scale: 1,
	  },
	  delay: i * 40,
	  config: {
		mass: 2,
		friction: 20,
		tension: 220,
	  },
	}))
  
	useChain([gridApi, boxApi], [0, 1], 1500)
  return (
    <Content>
		<div className="background-container">
			<div className="container">
				<svg viewBox={`0 0 ${MAX_WIDTH} ${MAX_HEIGHT}`}>
					<g>
						{gridSprings.map(({ x2 }, index) => (
						<animated.line
							x1={0}
							y1={index * 10 + OFFSET}
							x2={x2}
							y2={index * 10 + OFFSET}
							key={index}
							strokeWidth={STROKE_WIDTH}
							stroke='var(--primary)'
						/>
						))}
						{gridSprings.map(({ y2 }, index) => (
						<animated.line
							x1={index * 10 + OFFSET}
							y1={0}
							x2={index * 10 + OFFSET}
							y2={y2}
							key={index}
							strokeWidth={STROKE_WIDTH}
							stroke='var(--primary)'
						/>
						))}
					</g>
					{boxSprings.map(({ scale }, index) => (
						<animated.rect
						key={index}
						width={10}
						height={10}
						stroke='var(--primary)'
						style={{
							transformOrigin: `${5 + OFFSET * 2}px ${5 + OFFSET * 2}px`,
							transform: `translate(${COORDS[index][0] + OFFSET}px, ${COORDS[index][1] + OFFSET}px)`,
							scale,
						}}
						/>
					))}
				</svg>
			</div>
		</div>
    </Content>
  )
}

export default NoGrid;