import React, { useState, useCallback, useRef, useEffect } from 'react'
import styled from "styled-components";
import { useTransition, animated } from '@react-spring/web'

const Content = styled.div`
.main {
    min-width: 100px;
    padding: 0 20px;
    margin: 0 auto;
    height: 260px;
  }
  
  .transitionsItem {
    overflow: hidden;
    width: 100%;
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 4em;
    font-weight: 800;
    text-transform: uppercase;
    will-change: transform, opacity, height;
    white-space: nowrap;
    cursor: pointer;
    line-height: 80px;
  }
  
  .container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
  
`

const MovingWords = () => {
    const ref = useRef<ReturnType<typeof setTimeout>[]>([])
    const [items, set] = useState<string[]>([])
    const transitions = useTransition(items, {
        from: {
            opacity: 0,
            height: 0,
            innerHeight: 0,
            transform: 'perspective(600px) rotateX(0deg)',
            color: '#fff',
        },
        enter: [
            { opacity: 1, height: 80, innerHeight: 80 },
            { transform: 'perspective(100px) rotateX(180deg)', color: 'var(--primary)' },
            { transform: 'perspective(100px) rotateX(0deg)' },
        ],
        leave: [{ color: '#fff' }, { innerHeight: 0 }, { opacity: 0, height: 0 }],
        update: { color: 'var(--primary)' },
        })

        const reset = useCallback(() => {
            ref.current.forEach(clearTimeout)
            ref.current = []
            set([])
            ref.current.push(setTimeout(() => set(['NO', 'NOT YET']), 1500))
            ref.current.push(setTimeout(() => set(['NOPE']), 4000))
            ref.current.push(setTimeout(() => set(['SOON?']), 6000))
        }, [])

        useEffect(() => {
            reset()
            return () => ref.current.forEach(clearTimeout)
        }, [])

    return (
        <Content>
            <div className="container">
                <div className="main">
                    {transitions(({ innerHeight, ...rest }, item) => (
                        <animated.div className="transitionsItem" style={rest} onClick={reset}>
                            <animated.div style={{ overflow: 'hidden', height: innerHeight }}>{item}</animated.div>
                        </animated.div>
                    ))}
                </div>
            </div>
        </Content>
    )
}

export default MovingWords;