import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  padding: 0 16px;
`;

const Quote = styled.p`
  font-size: 16px;
  font-style: italic;

  @media only screen and (min-width: 768px) {
    font-size: 32px;
  }
`;

const Author = styled.p`
  margin: 30px 0 0;
  font-size: 16px;

  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }
`;

const Shill3r = () => {
  return (
    <Content>
      <Quote>
        &quot;ngl, if we tricked all the kids buying Logan Pauls new PRIME drink that is associated
        with $PRIME, we might get to $111 sooner than later LMAO&quot;
      </Quote>
      <Author>- SHILL3R</Author>
    </Content>
  );
};

export default Shill3r;
