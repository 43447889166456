import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  .wrapper.nine {
  }
  .rotate span {
    color: #f8f8f8;
    display: inline-flex;
    animation: rotate 2s infinite;
    font-size: 48px;

    @media only screen and (min-width: 768px) {
      font-size: 96px;
    }
  }
  @keyframes rotate {
    0%,
    75% {
      transform: rotateY(360deg);
    }
  }
  .nine span:nth-of-type(1) {
    animation-delay: 0.2s;
  }
  .nine span:nth-of-type(2) {
    animation-delay: 0.4s;
  }
  .nine span:nth-of-type(3) {
    animation-delay: 0.6s;
  }
  .nine span:nth-of-type(4) {
    animation-delay: 0.8s;
  }
  .nine span:nth-of-type(5) {
    animation-delay: 1s;
  }
  .nine span:nth-of-type(6) {
    animation-delay: 1.2s;
  }
  .nine span:nth-of-type(7) {
    animation-delay: 1.4s;
  }
`;

const RotateText = () => {
  return (
    <Content>
      <div className="wrapper nine">
        <div>
          <h3 className="rotate">
            <span>S</span>
            <span>O</span>
            <span>M</span>
            <span>E</span>
            <span>D</span>
            <span>A</span>
            <span>Y</span>
          </h3>
        </div>
      </div>
    </Content>
  );
};

export default RotateText;
