import React from "react";
import styled from "styled-components";

const Content = styled.div``  

const Subheading = styled.h2`
    color: var(--marcolian);
    font-size: 16px;
    text-transform: uppercase;

    @media only screen and (min-width: 768px) {
        font-size: 32px;
    }
`

const Marcolian = () => {
    return (
        <Content>
            <Subheading>No, but in Marcolian red</Subheading>
        </Content>
    )
}

export default Marcolian;