import React from "react";
import styled from "styled-components";

const Section = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        transform-style: preserve-3d;
        perspective: 700px;

        .box {
            // position: absolute;
            transform-style: preserve-3d;
            top: 100px;
        }
        .box .cube {
            position: relative;
            width: 200px;
            height: 200px;
            transform-style: preserve-3d;
            animation: animateCube 5s linear infinite;
        }
        @keyframes animateCube {
            0% {
                transform: rotateY(0deg);
            }
            100% {
                transform: rotateY(360deg);
            }
        }
        .box .cube div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform-style: preserve-3d;
        }

        .box .cube .top {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 200px;
            height: 200px;
            background: #b22708;
            transform: rotateX(90deg) translateZ(100px);
        }
        .box .cube .top::before {
            content: "";
            position: absolute;
            width: 400px;
            height: 400px;
            background: rgba(222, 241, 65, 0.1);
            filter: blur(50px);
            transform: translateZ(-400px);
            box-shadow: 0 0 120px rgba(222, 241, 65, 0.2), 0 0 200px rgba(222, 241, 65, 0.4),
                0 0 300px rgba(222, 241, 65, 0.6), 0 0 400px rgba(222, 241, 65, 0.8),
                0 0 500px rgba(222, 241, 65, 1);
        }
`

const Span = styled.span<{styletype: string}>`
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: radial-gradient(var(--primary), #000);
    filter: brightness(.9);
    background: var(--primary);
    
    ${({ styletype }) => styletype == '0' ? `
        transform: rotateY(calc(90deg * 0)) translateZ(100px);
    ` : styletype == '1' ? `
        transform: rotateY(calc(90deg * 1)) translateZ(100px);
    ` : styletype == '2' ? `
        transform: rotateY(calc(90deg * 2)) translateZ(100px);
    ` : styletype == '3' ? `
        transform: rotateY(calc(90deg * 3)) translateZ(100px);
    ` : ``
    }
`

const Subheading = styled.h2`
    font-size: 32px;
    color: blue;
`

const TurningBox = () => {
    return (
        <Section>
            <div className="box">
                <div className="cube">
                    <div className="top"></div>
                    <div>
                        <Span styletype="0">NO</Span>
                        <Span styletype="1">NOT YET</Span>
                        <Span styletype="2">PATIENCE</Span>
                        <Span styletype="3">ALMOST?</Span>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default TurningBox;