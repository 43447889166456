import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  body {
    min-height: 100vh;
    font-weight: 300;
    font-size: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    background-color: #eff8e2;
  }

  .content {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .text_shadows {
    text-shadow: 3px 3px 0 var(--kathari), 6px 6px 0 var(--earthen), 9px 9px var(--shroud),
      12px 12px 0 var(--marcolian);
    font-family: bungee, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: calc(2rem + 5vw);
    text-align: center;
    margin: 0;
    color: var(--color-primary);
    //color: transparent;
    //background-color: white;
    //background-clip: text;
    animation: shadows 1.2s ease-in infinite, move 1.2s ease-in infinite;
    letter-spacing: 0.4rem;
  }

  @keyframes shadows {
    0% {
      text-shadow: none;
    }
    10% {
      text-shadow: 3px 3px 0 var(--kathari);
    }
    20% {
      text-shadow: 3px 3px 0 var(--kathari), 6px 6px 0 var(--earthen);
    }
    30% {
      text-shadow: 3px 3px 0 var(--kathari), 6px 6px 0 var(--earthen), 9px 9px var(--shroud);
    }
    40% {
      text-shadow: 3px 3px 0 var(--kathari), 6px 6px 0 var(--earthen), 9px 9px var(--shroud),
        12px 12px 0 var(--marcolian);
    }
    50% {
      text-shadow: 3px 3px 0 var(--kathari), 6px 6px 0 var(--earthen), 9px 9px var(--shroud),
        12px 12px 0 var(--marcolian);
    }
    60% {
      text-shadow: 3px 3px 0 var(--kathari), 6px 6px 0 var(--earthen), 9px 9px var(--shroud),
        12px 12px 0 var(--marcolian);
    }
    70% {
      text-shadow: 3px 3px 0 var(--kathari), 6px 6px 0 var(--earthen), 9px 9px var(--shroud);
    }
    80% {
      text-shadow: 3px 3px 0 var(--kathari), 6px 6px 0 var(--earthen);
    }
    90% {
      text-shadow: 3px 3px 0 var(--kathari);
    }
    100% {
      text-shadow: none;
    }
  }

  @keyframes move {
    0% {
      transform: translate(0px, 0px);
    }
    40% {
      transform: translate(-12px, -12px);
    }
    50% {
      transform: translate(-12px, -12px);
    }
    60% {
      transform: translate(-12px, -12px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }
`;

const ThreeDText = () => {
  return (
    <Content>
      <h2 className="text_shadows">Not yet</h2>
    </Content>
  );
};

export default ThreeDText;
