import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  // $font1: 'Poppins', sans-serif;
  // $font2: 'Lobster Two', cursive;
  $white: #fff;
  $black: #000;
  $primary-light: #f9fafb;
  $second-light: #f0f0f0;
  $third-light: #dadbdb;

  $primary-dark: #222;
  $second-grey: #4f4f4f;

  $first-shadow: rgba(0, 0, 0, 0.3);

  $success: rgba(61, 168, 102, 0.6);
  $error: rgba(168, 30, 14, 0.6);

  $primary-color: #5c5fc4;
  $second-color: #c4c15c;

  /* GLOBAL CONFIG RESET */
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  select:focus,
  input:focus,
  button:focus {
    outline: none;
  }

  /* -------- BODY DISPLAY --------- */
  body {
    background-color: $primary-dark;
    overflow: hidden;

    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;

    min-height: 100vh;
  }

  /* -------- TYPE SELECTORS --------- */

  /* -------- CLASS --------- */

  .container {
    display: flex;
    justify-content: center;
    align-items: center;

    .box {
      transform-style: preserve-3d;
      animation: animate 4s ease-in-out infinite alternate;

      span {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5) 90%, transparent);
        text-transform: uppercase;
        line-height: 0.76em;
        position: absolute;
        color: $white;
        font-size: 18px;
        white-space: nowrap;
        font-weight: bold;
        padding: 0px 10px;
        transform-style: preserve-3d;
        text-shadow: 0 10px 15px $first-shadow;
        transform: translate(-50%, -50%) rotateX(calc(1 * 22.5deg)) translateZ(109px);

        @media only screen and (min-width: 768px) {
          font-size: 48px;
        }

        i {
          font-style: initial;

          &:nth-child(1) {
            color: $primary-color;
          }

          &:nth-child(2) {
            color: $second-color;
          }
        }
      }
    }
  }

  @keyframes animate {
    0% {
      transform: perspective(500px) rotateX(0deg) rotate(5deg);
    }
    100% {
      transform: perspective(250px) rotateX(360deg) rotate(5deg);
    }
  }

  /* -------- PSEUDO CLASS --------- */

  /* -------- MEDIA QUERY --------- */

  @media (max-width: 800px) {
  }
`;

const ItsProgrammed = () => {
  return (
    <Content>
      <div className="container">
        <div className="box">
          <span>
            <i>IT`S</i>
            PRO
            <i>GRAMMED</i>
          </span>
          <span>
            <i>IT`S</i>
            PRO
            <i>GRAMMED</i>
          </span>
          <span>
            <i>IT`S</i>
            PRO
            <i>GRAMMED</i>
          </span>
          <span>
            <i>IT`S</i>
            PRO
            <i>GRAMMED</i>
          </span>
          <span>
            <i>IT`S</i>
            PRO
            <i>GRAMMED</i>
          </span>
          <span>
            <i>IT`S</i>
            PRO
            <i>GRAMMED</i>
          </span>
          <span>
            <i>IT`S</i>
            PRO
            <i>GRAMMED</i>
          </span>
          <span>
            <i>IT`S</i>
            PRO
            <i>GRAMMED</i>
          </span>
          <span>
            <i>IT`S</i>
            PRO
            <i>GRAMMED</i>
          </span>
          <span>
            <i>IT`S</i>
            PRO
            <i>GRAMMED</i>
          </span>
          <span>
            <i>IT`S</i>
            PRO
            <i>GRAMMED</i>
          </span>
          <span>
            <i>IT`S</i>
            PRO
            <i>GRAMMED</i>
          </span>
          <span>
            <i>IT`S</i>
            PRO
            <i>GRAMMED</i>
          </span>
          <span>
            <i>IT`S</i>
            PRO
            <i>GRAMMED</i>
          </span>
          <span>
            <i>IT`S</i>
            PRO
            <i>GRAMMED</i>
          </span>
          <span>
            <i>IT`S</i>
            PRO
            <i>GRAMMED</i>
          </span>
        </div>
      </div>
    </Content>
  );
};

export default ItsProgrammed;
