import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  .title-word {
    animation: color-animation 4s linear infinite;
    padding: 0 16px;
  }

  .title-word-1 {
    --color-1: var(--marcolian);
    --color-2: var(--earthen);
    --color-3: var(--kathari);
  }

  .title-word-2 {
    --color-1: var(--shroud);
    --color-2: var(--primary);
    --color-3: var(--augencore);
  }

  @keyframes color-animation {
    0% {
      color: var(--color-1);
    }
    32% {
      color: var(--color-1);
    }
    33% {
      color: var(--color-2);
    }
    65% {
      color: var(--color-2);
    }
    66% {
      color: var(--color-3);
    }
    99% {
      color: var(--color-3);
    }
    100% {
      color: var(--color-1);
    }
  }

  .container {
    display: grid;
    place-items: center;
    text-align: center;
    height: 100vh;
  }

  .title {
    font-weight: 800;
    font-size: 8.5vw;
    text-transform: uppercase;
  }
`;

const SlidingColor = () => {
  return (
    <Content>
      <h2 className="title">
        <span className="title-word title-word-1">NOT</span>
        <span className="title-word title-word-2">YET</span>
      </h2>
    </Content>
  );
};

export default SlidingColor;
