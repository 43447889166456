import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    font-size: 40px;
  }

  .animation {
    height: 24px;
    overflow: hidden;
    margin-left: 7px;

    @media only screen and (min-width: 768px) {
      height: 60px;
      margin-left: 15px;
    }
  }

  .animation > div > div {
    padding: 5px;
    height: 24px;
    margin-bottom: 10px;
    display: inline-block;

    @media only screen and (min-width: 768px) {
      height: 60px;
      margin-bottom: 20px;
    }
  }

  .animation div:first-child {
    animation: text-animation 6s infinite;
  }

  .first div {
    background-color: var(--primary);
    color: black;
  }
  .second div {
    background-color: var(--primary);
    color: black;
  }
  .third div {
    background-color: var(--primary);
    color: black;
  }

  @keyframes text-animation {
    0% {
      margin-top: 0;
    }
    10% {
      margin-top: 0;
    }
    20% {
      margin-top: -26px;
    }
    30% {
      margin-top: -26px;
    }
    40% {
      margin-top: -52px;
    }
    60% {
      margin-top: -52px;
    }
    70% {
      margin-top: -26px;
    }
    80% {
      margin-top: -26px;
    }
    90% {
      margin-top: 0;
    }
    100% {
      margin-top: 0;
    }
  }

  @media only screen and (min-width: 768px) {
    @keyframes text-animation {
      0% {
        margin-top: 0;
      }
      10% {
        margin-top: 0;
      }
      20% {
        margin-top: -70px;
      }
      30% {
        margin-top: -70px;
      }
      40% {
        margin-top: -140px;
      }
      60% {
        margin-top: -140px;
      }
      70% {
        margin-top: -70px;
      }
      80% {
        margin-top: -70px;
      }
      90% {
        margin-top: 0;
      }
      100% {
        margin-top: 0;
      }
    }
  }
`;

const ChangingWords = () => {
  return (
    <Content>
      <p>No, but we</p>
      <section className="animation">
        <div className="first">
          <div>ARE PRIMED</div>
        </div>
        <div className="second">
          <div>LIKE THE CARDS</div>
        </div>
        <div className="third">
          <div>ARE BULLISH</div>
        </div>
      </section>
    </Content>
  );
};

export default ChangingWords;
