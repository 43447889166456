import React, { useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { RenderTexture, OrbitControls, PerspectiveCamera, Text, ContactShadows } from '@react-three/drei'
import styled from 'styled-components'

const CanvasWrapper = styled.div`
	height: auto;
`

export function ThreeBox() {
	return (
		<CanvasWrapper>
			<Canvas camera={{ position: [5, 5, 5], fov: 25 }}>
				<ambientLight intensity={0.5} />
				<directionalLight position={[10, 10, 5]} />
				<Cube />
				<ContactShadows frames={1} position={[0, -0.5, 0]} blur={1} opacity={0.75} />
				<ContactShadows frames={1} position={[0, -0.5, 0]} blur={3} color="#DEF141" />
				<OrbitControls minPolarAngle={0} maxPolarAngle={Math.PI / 2.1} />
			</Canvas>
		</CanvasWrapper>
	)
}

function Cube() {
	const textRef = useRef()
	useFrame((state) => (textRef.current.position.x = Math.sin(state.clock.elapsedTime) * 3))
	return (
		<mesh>
		<boxGeometry />
		<meshStandardMaterial>
			<RenderTexture attach="map" anisotropy={16}>
			<PerspectiveCamera makeDefault manual aspect={1 / 1} position={[0, 0, 5]} />
			<color attach="background" args={['#DEF141']} />
			<ambientLight intensity={0.5} />
			<directionalLight position={[10, 10, 5]} />
			<Text ref={textRef} fontSize={3} color="#000">
				NO
			</Text>
			</RenderTexture>
		</meshStandardMaterial>
		</mesh>
  )
}
