import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;

  .container {
    width: 90%;
    height: 1.15em;
    font-size: 40px;
    font-family: sans-serif;
    position: relative;
  }

  .container div {
    position: absolute;
    left: 0;
    text-transform: uppercase;
    width: 100%;
    display: block;
    text-align: center;
  }

  .upper {
    top: 0;
    height: 52.5%;
    color: #fff;
    overflow: hidden;
    z-index: 3;
    animation-delay: 2s;
    animation: moveUp 2.5s ease-in-out infinite;
    background-color: #000;
  }

  .lower {
    bottom: 0;
    height: 100%;
    background: linear-gradient(180deg, #000 52.5%, #fff 52.5%);
    background-color: #121212;
    color: transparent;
    //   background-clip: text;
    -webkit-background-clip: text;
    z-index: 1;
    animation-delay: 2s;
    animation: moveDown 2.5s ease-in-out infinite;
  }

  .inside {
    position: absolute;
    top: 20%;
    transform: translateY(-40%);
    text-align: center;
    z-index: 2;
    font-size: 24px;
    color: var(--primary);
  }

  @keyframes moveUp {
    0%,
    80% {
      top: 0;
    }

    30%,
    50% {
      top: -45px;
    }
  }

  @keyframes moveDown {
    00%,
    80% {
      top: 0;
    }

    30%,
    50% {
      top: 25px;
    }
  }

  @media (max-width: 424px) {
    .container {
      font-size: 2.5rem;
    }

    .inside {
      font-size: 0.75rem;
    }
  }

  @media (max-width: 320px) {
    .container {
      font-size: 2rem;
    }

    .inside {
      font-size: 0.5rem;
    }
  }
`;

const RotatingWordsCircle = () => {
  return (
    <Content>
      <div className="container">
        <div className="upper">No, but it will be</div>
        <div className="lower">No, but it will be</div>
        <div className="inside">$111</div>
      </div>
    </Content>
  );
};

export default RotatingWordsCircle;
