import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  .wrapper.two {
    // background-color: #2c0101;
    font-size: 32px;

    @media only screen and (min-width: 768px) {
      font-size: 64px;
    }
  }
  .neon h3 {
    margin: 1rem auto;
    animation: neon 1s ease infinite;
  }
  @keyframes neon {
    0%,
    100% {
      text-shadow: 0 0 30px var(--primary), 0 0 30px var(--primary), 0 0 px var(--primary),
        2px 2px 2px var(--primary);
      color: var(--primary);
    }
    50% {
      text-shadow: 0 0 40px var(--primary), 0 0 2px var(--primary), 2px 2px 2px var(--primary);
      color: white;
    }
  }
`;

const NoNeon = () => {
  return (
    <Content>
      <div className="wrapper two">
        <div className="neon">
          <h3>NOT YET</h3>
        </div>
      </div>
    </Content>
  );
};

export default NoNeon;
