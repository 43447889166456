import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  padding: 0 16px;
`;

const Quote = styled.p`
  font-size: 16px;
  font-style: italic;

  @media only screen and (min-width: 768px) {
    font-size: 32px;
  }
`;

const Author = styled.p`
  margin: 30px 0 0;
  font-size: 16px;

  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }
`;

const LennyStyles = () => {
  return (
    <Content>
      <Quote>&quot;Can the DEV`s do Something ?!&quot;</Quote>
      <Author>- Lenny Styles</Author>
    </Content>
  );
};

export default LennyStyles;
