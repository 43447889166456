import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    font-size: 24px;

    @media only screen and (min-width: 768px) {
      font-size: 48px;
    }
  }

  .container {
    display: inline-flex;
    flex-wrap: nowrap;
    color: var(--primary);
  }

  .container > span {
    animation: shake 0.5s infinite;
    color: var(--primary);
    margin: 0 0 0 10px;
  }

  @keyframes shake {
    0% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
    10% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    20% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    100% {
      transform: translate(1px, 1px) rotate(0deg);
    }
  }
`;

const Shaking = () => {
  return (
    <Content>
      <div className="container">
        No, but<span> soooooon</span>
      </div>
    </Content>
  );
};

export default Shaking;
