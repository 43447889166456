import React from 'react';
import styled from 'styled-components';
import { a, useSpring } from '@react-spring/web';

const Content = styled.div`
	width: 100%;
	// height: calc(100% - 100px);
	display: flex;
	justify-content: center;
	padding-top: 100px;
}

.test {
	width: 100px;	
	height: 100px;
	background: var(--primary);
	font-size: 40px;
	color: #000;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;
}

`;

const FlippingBox = () => {
  const style = useSpring({
    from: { rotateZ: 0 },
    to: { rotateZ: 720 },
    loop: true,
    delay: 1000,
  });

  return (
    <Content>
      <div className="App">
        <a.div className="test" style={style}>
          NO
        </a.div>
      </div>
    </Content>
  );
};

export default FlippingBox;
