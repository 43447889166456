import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper.eight {
    background-color: #000000;
    overflow: hidden;
    color: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 32px;
    @media only screen and (min-width: 768px) {
      font-size: 64px;
    }
  }
  .eight .smoke {
    margin: 3rem auto;
    display: inline-flex;
  }
  .eight span {
    display: inline-flex;
    text-shadow: 0 0 0 #f5f5f5;
    animation: smoke 5s ease infinite;
  }

  @keyframes smoke {
    60% {
      text-shadow: 0 0 40px #f5f5f5;
    }
    to {
      transform: translate3d(12rem, -10rem, 0) skewX(50deg);
      text-shadow: 0 0 20px #f5f5f5;
      opacity: 0;
    }
  }

  .eight span:nth-of-type(1) {
    animation-delay: 2.1s;
  }
  .eight span:nth-of-type(2) {
    animation-delay: 2.2s;
  }
  .eight span:nth-of-type(3) {
    animation-delay: 2.3s;
  }
  .eight span:nth-of-type(4) {
    animation-delay: 2.4s;
  }
  .eight span:nth-of-type(5) {
    animation-delay: 2.5s;
  }
`;

const Smoke = () => {
  return (
    <Content>
      <div className="wrapper eight">
        <h3 className="smoke">
          <span>S</span>
          <span>O</span>
          <span>O</span>
          <span>N</span>
          <span>?</span>
        </h3>
      </div>
    </Content>
  );
};

export default Smoke;
